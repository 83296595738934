import { Container, Row, Col } from "reactstrap";

const Lyke = () => {
  return (
    <>
      <div className="features-5">
        <Container>
          <Row>
            <Col md="5">
              <div className="lykeWrapper">
                <h2 className="title">
                  <span
                    style={{
                      color: "#024d9c",
                      fontWeight: "bold",
                      paddingTop: "70px",
                    }}
                  >
                    DLA KOGO
                  </span>{" "}
                  JEST SENSOLYKE?
                </h2>
              </div>
              <div className="info info-horizontal info-hover">
                <div className="icon icon-azure icon-circle">
                  <i className="now-ui-icons shopping_shop"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">ŚWIADOMI RODZICE</h4>
                  <p>
                    Rodzice świadomi potrzeb swoich dzieci docenią rozwiązania
                    SensoLyke za wieloobszarowość i wygodę. Od kształtowania
                    Integracji Sensorycznej po wyciszenie i relaksację, Bukiny
                    dostarczają dzieciom profesjonalnych narzędzi rozwojowych w
                    domowym zaciszu.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal info-hover">
                <div className="icon icon-azure icon-circle">
                  <i className="now-ui-icons emoticons_satisfied"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">PRZEDSZKOLA</h4>
                  <p>
                    Bukiny stały się stałym elementem wyposażenia nowoczesnych
                    placówek przedszkolnych i opiekuńczych, które w trosce o
                    podopiecznych pragną zapewnić im elementy terapii Integracji
                    Sensorycznej. Pomożemy Ci ustalić zapotrzebowanie i
                    wzbogacić przedszkole o nową jakość.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal info-hover">
                <div className="icon icon-azure icon-circle">
                  <i className="now-ui-icons ui-1_send"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">SALKI TERAPEUTYCZNE</h4>
                  <p>
                    Pomagamy wyposażyć sale warsztatowe i terapeutyczne w pomoce
                    do sensoryki dużej. W zależności od potrzeb Twojego
                    gabinetu, pomożemy Ci dobrać kompletne wyposażenie
                    umożliwiające prowadzenie terapii Integracji Sensorycznej na
                    wyjątkowych narzędziach wysokiej jakości.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="tablet-container">
                <img
                  alt="zdjęcie bukina sensorycznego"
                  src={require("assets/img/telefon.png")}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Lyke;
