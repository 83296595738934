/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  NavLink,
} from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";

import logo from "../assets/img/sensologo.png";

function LykeNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const hideCollapseHandler = (e) => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(false);
  };

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand tag={Link} to="/">
              <img
                style={{ height: "57px", width: "80px" }}
                src={logo}
                onClick={() => {
                  document.documentElement.classList.remove("nav-open");
                  setCollapseOpen(false);
                }}
              ></img>
            </NavbarBrand>
            <div className="navbar-button">
              <button
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(!collapseOpen);
                }}
                aria-expanded={collapseOpen}
                className="navbar-toggler"
              >
                <span className="navbar-toggler-bar top-bar"></span>
                <span className="navbar-toggler-bar middle-bar"></span>
                <span className="navbar-toggler-bar bottom-bar"></span>
              </button>{" "}
            </div>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="mx-auto" id="ceva" navbar>
              <NavItem>
                <NavLink
                  style={{ fontWeight: "500", fontSize: "12px" }}
                  tag={RRNavLink}
                  activeClassName="selected"
                  exact
                  to="/"
                  onClick={(e ) => hideCollapseHandler(e)}
                >
                  HOME
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontWeight: "500", fontSize: "12px" }}
                  tag={RRNavLink}
                  activeClassName="selected"
                  to="/oferta"
                  onClick={hideCollapseHandler}
                >
                  OFERTA
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontWeight: "500", fontSize: "12px" }}
                  tag={RRNavLink}
                  activeClassName="selected"
                  to="/swiat-lyke"
                  onClick={hideCollapseHandler}
                >
                  ŚWIAT LYKE
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontWeight: "500", fontSize: "12px" }}
                  tag={RRNavLink}
                  activeClassName="selected"
                  to="/klienci"
                  onClick={hideCollapseHandler}
                >
                  NASI KLIENCI
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  style={{ fontWeight: "500", fontSize: "12px" }}
                  href="http://sklep.sensolyke.pl"
                >
                  SKLEP
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  style={{ fontWeight: "500", fontSize: "12px" }}
                  tag={RRNavLink}
                  activeClassName="selected"
                  to="/kontakt"
                  onClick={hideCollapseHandler}
                >
                  KONTAKT
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default LykeNavbar;
