import React from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";

// core components
import LykeFooter from "./LykeFooter";
import LykeContactMap from "./LykeContactMap";

function LykeContact() {
  const [form, setForm] = React.useState({});

  const sendMessage = () => {
    axios
      .post("https://netluck.pl:9030/sensolyke/mail", form)
      .then(() => {
        toast.success("Wiadomość wysłana");

        setForm({});
      })
      .catch(() => {
        toast.error("Wystąpił problem - prosimy spróbować później");
      });
  };

  const [nameFocus, setNameFocus] = React.useState(false);
  const [lastnameFocus, setLastameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);

  const formChange = (e) => {
    const { name, value } = e.target;
    const tmp = { ...form };
    tmp[name] = value;
    setForm(tmp);
  };

  React.useEffect(() => {
    document.body.classList.add("contact-page");
  }, []);

  return (
    <div className="">
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/contact.jpg") + ")",
            backgroundPositionY: "56%",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(7, 78, 157, 0.6)",
            }}
          ></div>
        </div>
      </div>{" "}
      <div className="main">
        <div className="contact-content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card
                  className="card-contact card-raised"
                  style={{
                    borderRadius: "25px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <h2 className="title">
                    <span style={{ color: "#024d9c", fontWeight: "bold" }}>
                      SKONTAKTUJ
                    </span>{" "}
                    SIĘ
                  </h2>
                  <p className="description">
                    Zainteresowaliśmy Cię? Masz pytania dotyczące SensoLyke?
                    Wybierz najwygodniejszy dla siebie sposób kontaktu i daj nam
                    znać jak możemy Ci pomóc.<br></br>
                    <br></br>
                  </p>
                  <Form id="contact-form" method="post" role="form">
                    <label>Imię</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Imię..."
                        type="text"
                        required
                        onChange={formChange}
                        onFocus={(e) => setNameFocus(true)}
                        onBlur={(e) => setNameFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Nazwisko</label>
                    <InputGroup
                      className={lastnameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons text_caps-small"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="lastname"
                        placeholder="Nazwisko..."
                        type="text"
                        onChange={formChange}
                        onFocus={(e) => setLastameFocus(true)}
                        onBlur={(e) => setLastameFocus(false)}
                        required
                      ></Input>
                    </InputGroup>
                    <label>Adres e-mail</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email..."
                        type="email"
                        onChange={formChange}
                        required
                        onFocus={(e) => setEmailFocus(true)}
                        onBlur={(e) => setEmailFocus(false)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label>Wiadomość</label>
                      <Input
                        id="message"
                        name="message"
                        placeholder="Twoja wiadomość..."
                        rows="6"
                        type="textarea"
                        onChange={formChange}
                        required
                      ></Input>
                    </FormGroup>
                    <div className="submit text-center">
                      <Button
                        className="btn-raised btn-round"
                        style={{
                          backgroundColor: "#024d9c",
                          fontWeight: "bold",
                        }}
                        color="info"
                        defaultValue="Contact Us"
                        onClick={sendMessage}
                      >
                        Wyślij
                      </Button>
                    </div>
                  </Form>
                </Card>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
                <div className="info info-horizontal mt-5">
                  <div className="icon icon-azure">
                    <i className="now-ui-icons location_pin"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Adres</h4>
                    <p>
                      Franklina Roosevelta 22, <br></br>
                      60-829 Poznań <br></br>{" "}
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-azure">
                    <i className="now-ui-icons tech_mobile"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Kontakt</h4>
                    <p>
                      Telefon - (+48) 885 444 255<br></br>
                      Mail: biuro@sensolyke.pl<br></br>
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-azure">
                    <i className="business_briefcase-24 now-ui-icons"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Dane</h4>
                    <p>
                      LYKE SP. Z O.O. <br></br>
                      NIP 7822649658 <br></br>
                      REGON 365559747 <br></br>
                      KRS 0000641727
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <LykeContactMap />
      <LykeFooter />
      <Toaster />
    </div>
  );
}

export default LykeContact;
