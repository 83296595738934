import React from "react";
// react plugin used to create DropdownMenu for selecting items

// reactstrap components
import { Container, Row, Col } from "reactstrap";

const LykeUnderHeader = () => {
  return (
    <>
      <div
        className="features-2 section-image"
        style={{
          backgroundImage:
            "url(" + require("assets/img/backgroundsection.png") + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container fluid>
          <Row>
            <Col className="mr-auto ml-auto" md="8">
              <h2 className="title" style={{ paddingTop: "60px" }}>
                <strong>Nasze</strong> wartości wyrosły z{" "}
                <strong>Twoich</strong> potrzeb
              </h2>
              <h4 className="description">
                Te wartości są drogowskazem, który pomaga nam zapewnić Ci tylko
                to, co najlepsze
              </h4>
            </Col>
          </Row>
          <Row>
            <Col md="4" sm="6">
              <div className="info info-hover">
                <div className="icon icon-azure icon-circle">
                  <i className="now-ui-icons objects_planet"></i>
                </div>
                <h4 className="header-title">INTERDYSCYPLINARNOŚĆ</h4>
                <p className="description">
                  SensoLyke to autorskie podejście do rozwoju dzieci, oparte na
                  Integracji Sensorycznej i higienie zdrowia psychicznego z
                  elementami profilaktyki uzależnień behawioralnych. Sięga do
                  nie tylko do SI, ale i psychologii czy pedagogiki.
                </p>
              </div>
            </Col>
            <Col md="4" sm="6">
              <div className="info info-hover">
                <div className="icon icon-azure icon-circle">
                  <i className="now-ui-icons ui-2_favourite-28"></i>
                </div>
                <h4 className="header-title">WSPARCIE</h4>
                <p className="description">
                  Wspieramy rozwój jednostki w kształtowaniu nowych,
                  wzmocnionych umiejętności. Wspieramy też każdą osobę, która na
                  ścieżce wspierania innych potrzebuje do tego odpowiednich
                  narzędzi i inspiracji.
                </p>
              </div>
            </Col>
            <Col md="4" sm="6">
              <div className="info info-hover">
                <div className="icon icon-azure icon-circle">
                  <i className="now-ui-icons design_app"></i>
                </div>
                <h4 className="header-title">KOMPLEKSOWOŚĆ</h4>
                <p className="description">
                  SensoLyke daje kompleksowy zestaw narzędzi ułatwiających
                  rozwój fizyczny i psychiczny dzieci. To nie tylko Bukiny
                  dobierane według potrzeb, ale i pakiet wiedzy z ćwiczeniami i
                  zabawami wykorzystującymi Bukiny.
                </p>
              </div>
            </Col>
            <Col md="4" sm="6">
              <div className="info info-hover">
                <div className="icon icon-azure icon-circle">
                  <i className="now-ui-icons objects_spaceship"></i>
                </div>
                <h4 className="header-title">ELASTYCZNOŚĆ</h4>
                <p className="description">
                  Bukiny to narzędzia, które dostosowujesz do siebie. Możesz
                  używać ich kiedy i jak chcesz, wydobywając z nich te
                  właściwości, których akurat Ci potrzeba.
                </p>
              </div>
            </Col>
            <Col md="4" sm="6">
              <div className="info info-hover">
                <div className="icon icon-azure icon-circle">
                  <i className="now-ui-icons education_glasses"></i>
                </div>
                <h4 className="header-title">PROFESJONALIZM</h4>
                <p className="description">
                  Metoda SensoLyke, materiały edukacyjne i Bukiny stworzone są
                  przez specjalistów w zakresie terapii Integracji Sensorycznej,
                  pedagogiki i psychologii, czyli zespół bogaty w kluczową
                  wiedzę i doświadczenie.
                </p>
              </div>
            </Col>
            <Col md="4" sm="6">
              <div className="info info-hover">
                <div className="icon icon-azure icon-circle">
                  <i className="now-ui-icons business_bulb-63"></i>
                </div>
                <h4 className="header-title">INNOWACYJNOŚĆ</h4>
                <p className="description">
                  SensoLyke to innowacyjne połączenie Integracji Sensorycznej i
                  wczesnej profilaktyki uzależnień behawioralnych z treningiem
                  kompetencji komunikacyjnych, proponowane dzieciom już w wieku
                  przedszkolnym.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default LykeUnderHeader;
