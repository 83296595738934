import React from "react";

// reactstrap components
import { Container, Row, Col, Carousel, CarouselItem } from "reactstrap";
import opinions from "./Opinions";

// core components

function LykeOpinion() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === opinions.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? opinions.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "0px" }}>
        <span style={{ color: "#024d9c", fontWeight: "bold" }}>OPINIE</span>{" "}
        NASZYCH KLIENTÓW
      </h2>
      <div className="testimonials-2">
        <Container>
          <Row>
            <Col md="12">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                {opinions.map((opinion) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={opinion.src}
                    >
                      {opinion.content}
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LykeOpinion;
