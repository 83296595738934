function InvalidUrl() {
  return (
    <>
      <h1>ERROR 404</h1>
      <h3>PAGE NOT FOUND</h3>
    </>
  );
}

export default InvalidUrl;
