import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

const LykeHeader = () => {
  return (
    <>
      <div
        className="page-header header-filter"
        style={{
          backgroundImage:
            "url(" + require("assets/img/sensolykemain.jpg") + ")",
          backgroundPosition: "center 65%",
          backgroundSize: "cover",
          height: "82vh",
        }}
      >
        <Container>
          <span className="headerTitle">
            Senso<b>LYKE</b>
          </span>
          {/*<span className="headerTitleReverse">*/}
          {/*  Integracja sensoryczna <br /> = <br /> lepsze zdrowie psychiczne*/}
          {/*    /!*Integracja sensoryczna <br /> to klucz <br/> do lepszego rozwoju <br/> i  <br />zdrowia psychicznego!*!/*/}
          {/*</span>*/}
        </Container>
      </div>{" "}
    </>
  );
};

export default LykeHeader;
