import React from "react";

import LykeHeader from "components/LykeHeader.js";
import LykeSection from "components/LykeSection.js";
import LykeFooter from "components/LykeFooter";
import Lyke from "./Lyke";
import LykeHeaderOne from "./LykeUnderHeader";
import LykePreFooter from "./LykePreFooter";
import CookieConsent from "react-cookie-consent";
import LykeMapOfCustomers from "./LykeMapOfCustomers";

function Main() {
  return (
    <>
      <LykeHeader />
      <LykeSection />
      <LykeHeaderOne />
      <Lyke />
      <LykePreFooter />
      <CookieConsent
        location="bottom"
        buttonText="Rozumiem"
        buttonStyle={{
          background: "#074e9d",
          borderRadius: "70px",
          color: "white",
          fontWeight: "600",
          fontSize: "14px",
          margin: "auto",
          marginTop: "20px",
          border: "none",
          padding: "13px 20px",
        }}
        disableStyles={true}
        expires={150}
      >
        Klikając "
        <span style={{ color: "#074e9d", fontWeight: "700" }}>Rozumiem</span>",
        zgadzasz się na przechowywanie plików cookie na swoim urządzeniu w celu
        usprawnienia nawigacji na stronie, analizy i pomocy w naszych
        działaniach marketingowych.
      </CookieConsent>
      <LykeFooter />
    </>
  );
}

export default Main;
